import { useState } from "react";
import { HDLModuleWASM } from "./sim/hdlwasm";
import { ExpectedSignal, useAnimationFrame } from "./simulation_options";
import Box from "@mui/joy/Box";

export const expectedLedSignals: ExpectedSignal[] =
  [
    { name: "clk", type: "clock" },
    { name: "led", type: "inv &bool" },
  ]

export const LedOutput = ({ hdlMod }: { hdlMod: HDLModuleWASM }) => {
  const [on, setOn] = useState(true);

  useAnimationFrame((deltaTimeMs: number) => {
    if (hdlMod) {
      // Simulating a clock frequency of 10 KHz
      const ccsToSimulate = 10 * deltaTimeMs;
      for (let i = 0; i < ccsToSimulate; i++) {
        hdlMod.tick()
        hdlMod.tick()
      }

      setOn(hdlMod.state.led == 1)
    }
  })

  if (on) {
    const shadow = "0 0 20px rgba(255, 0, 0, 0.91)"
    return <Box sx={{
      margin: "20px",
    }}>
      <Box sx={{
        marginLeft: "5px",
        width: 50,
        height: 70,
        backgroundColor: "red",
        borderTopLeftRadius: "25px",
        borderTopRightRadius: "25px",
        boxShadow: shadow,
      }}></Box>
      <Box sx={{
        width: 60,
        height: 10,
        backgroundColor: "red",
        boxShadow: shadow,
      }}></Box>
    </Box>
  } else {
    return <Box sx={{
      margin: "20px",
    }}>
      <Box sx={{
        marginLeft: "5px",
        width: 50,
        height: 70,
        backgroundColor: null,
        borderTopLeftRadius: "25px",
        borderTopRightRadius: "25px",
        border: "rgb(150, 0, 0) 2px solid",
      }}></Box>
      <Box sx={{
        width: 60,
        height: 10,
        backgroundColor: null,
        border: "rgb(150, 0, 0) 2px solid",
      }}></Box>
    </Box>
  }

}
