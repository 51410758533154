entity blinky(clk: clock, rst: bool, btn: bool) -> bool {
  // For performance reasons, the LED simulation is capped to 10 KHz, so we use
  // a lower duration than we would in hardware where things would run at >10 MHz
  // most of the time
  let duration = 10_000;
  reg(clk) count: uint<15> reset(rst: 0) =
    if count == duration {
      0
    } else {
      trunc(count + 1)
    };

  count > (duration / 2)
}

// In order to interface with the external world, both the simulator here
// and actual hardware, we need to change the signature of the entity a bit
// If you're just starting out, don't worry about this part!
#[no_mangle]
entity top(#[no_mangle] clk: clock, #[no_mangle] led: inv &bool, #[no_mangle] btn: bool) {
  reg(clk) rst initial(true) = false;

  set led = inst blinky(clk, rst, btn);
}
