import { createRoot } from 'react-dom/client';

import * as React from 'react';

import { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import '@fontsource/inter/latin-400';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import ButtonGroup from '@mui/joy/ButtonGroup';
import IconButton from '@mui/joy/IconButton';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Snackbar from '@mui/joy/Snackbar';
import Alert from '@mui/joy/Alert';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import useMediaQuery from '@mui/material/useMediaQuery';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ShareIcon from '@mui/icons-material/Share';

import CodeMirror from '@uiw/react-codemirror';
import { verilog } from 'codemirror-lang-verilog'
import { eclipse } from '@uiw/codemirror-theme-eclipse';


import { presets } from './config';

import './app.css';
import { Command, Product, asyncRunner } from './command';
import { runVerilator } from './verilator_yowasp';
import { getFileInTree } from './sim/util';
import { HDLModuleWASM } from './sim/hdlwasm';
import { RenderOutput, SimOutputKind } from './simulation_options'
import { terminal } from './terminal';
import { helloText } from './hello_text';
import Link from '@mui/joy/Link';
import { StreamLanguage } from '@codemirror/language';
import { spade } from './codemirror_spade';
import { githubDark } from '@uiw/codemirror-theme-github';
import { FancyButton } from './fancy_button';


function stealHashQuery() {
  const { hash } = window.location;
  if (hash !== '') {
    history.replaceState(null, '', ' '); // remove #... from URL entirely
    const hashQuery = hash.substring(1);
    try {
      const result = JSON.parse(atob(hashQuery))
      console.log(`Got ${result} from query`)
      return result;
    } catch {
    }
  }
}



function handleIostream(s: Uint8Array | string | null, setter: React.Dispatch<React.SetStateAction<string | null>>) {
  let decoder = new TextDecoder()
  if (s != null) {
    if (typeof s === "string") {
      setter((prev) => prev === null ? s : prev + s)
    }
    else {
      let newNow = decoder.decode(s, { stream: true })
      setter((prev) => prev === null ? newNow : prev + newNow)
    }
  }
}


function Buttons({ count, setButtons }: { count: number, setButtons: (arg0: number) => void }) {
  if (count) {
    return <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: "3px" }}>
        {[...Array(count).keys()].map((num) => {
          console.log(num)
          return <FancyButton
            onPointerDown={() => {
              setButtons((1 << num))
            }}
            onPointerUp={() => {
              setButtons(0)
            }}
            key={num}
          />
        })}
      </Box>
    </Box>
  }
}

function Switches({ switchCount }: { switchCount: number, }) {
  // Cursedness: 87%. The ToggleButtonGroup looks pretty, but it works with strings,
  // one for each button, not an array of pressed buttons or anything. We'll work
  // around that by generating buttons with the correct 'value' string for each digit,
  // then translate between an array of strings like ["1", "3", "4"] and the binary repr
  // in this case 0b0101_1000
  if (switchCount) {
    return (
      <Box>
        <span>Switches:</span>
        <ToggleButtonGroup
          value={[...Array(switchCount).keys()]
            .filter((num) => {
              return ((switches >> num) & 1) == 1
            })
            .map((num) => num.toString())
          }
          onChange={
            (_, newValue) => {
              let newValueInt = 0
              for (const digit of newValue) {
                newValueInt = newValueInt | (1 << parseInt(digit))
              }
              setSwitches(newValueInt)
            }
          }
        >
          {[...Array(count).keys()].map((num) => {
            return <Button value={num.toString()}>{num}</Button>
          })}
        </ToggleButtonGroup>
      </Box>
    )
  }
}



function AppContent() {
  const { mode, setMode } = useColorScheme();

  const query: { spadeSource?: string, tomlSource?: string, simKind?: string } | undefined = stealHashQuery();
  const [running, setRunning] = useState(false);
  const [activeLeftTab, setActiveLeftTab] = useState('spade-source');
  const [activeRightTab, actualSetActiveRightTab] = useState('tutorial');
  const [sourceCode, setSourceCode] = useState(
    query?.spadeSource
    ?? localStorage.getItem('spade-playground.source')
    ?? presets["simpleVga"].spade);
  useEffect(() => localStorage.setItem('spade-playground.source', sourceCode), [sourceCode]);

  const [tomlCode, setTomlCode] = useState(
    query?.tomlSource
    ?? localStorage.getItem('spade-playground.toml')
    ?? presets["simpleVga"].toml);
  useEffect(() => localStorage.setItem('spade-playground.toml', tomlCode), [tomlCode]);
  const [sharingOpen, setSharingOpen] = useState(false);
  const [inputHelpOpen, setInputHelpOpen] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 800px)')

  const [commandOutput, setCommandOutput] = useState<string | null>(null);
  const [productsOutOfDate, setProductsOutOfDate] = useState(false);
  const [verilogProduct, setVerilogProduct] = useState<string | null>(null);
  const [hdlMod, setHdlMod] = useState<HDLModuleWASM | null>(null);

  const [simKind, setSimKind] = useState<SimOutputKind>(
    {
      kind: query?.simKind
        ?? localStorage.getItem("sim-kind")
        ?? presets["simpleVga"].hardware.kind
    }
  );

  // const [simulatedHardware, setSimulatedHardware] = useState<SimOutputKind>(new VgaOutput)
  const [switches, setSwitches] = useState<number>(0)
  const [buttons, setButtons] = useState<number>(0)

  const setActiveRightTab = (tabName: string) => {
    if (isLargeScreen) {
      actualSetActiveRightTab(tabName)
    } else {
      setActiveLeftTab(tabName)
    }
  }

  const onPresetChanged = (preset: string) => {
    if (presets[preset] === undefined) {
      console.log(`Unkown preset ${preset}`)
      return
    }

    setSourceCode(presets[preset].spade)
    setTomlCode(presets[preset].toml)
    setSimKind(presets[preset].hardware)
    setHdlMod(null)
  }

  useEffect(() => {
    [localStorage.setItem("sim-kind", simKind.kind)]
  }, [simKind]);


  async function runCommands(commands: Command[], onDone?: () => void) {
    let failed = false;
    if (running)
      return;

    setCommandOutput(null)
    if (hdlMod) {
      hdlMod.dispose()
    }
    setHdlMod(null)
    setActiveRightTab('command-output')

    let files = {
      "src": { "playground.spade": sourceCode },
      "swim.toml": tomlCode
    }

    for (const cmd of commands) {
      setRunning(true);
      setProductsOutOfDate(false);

      const handlers = {
        stdout: (s) => handleIostream(s, setCommandOutput),
        stderr: (s) => handleIostream(s, setCommandOutput),
      }

      handlers.stdout(`[Playground] Running ${cmd.name} ${cmd.args}\n`)

      try {
        files = await cmd.runner(cmd.args, files, handlers)

        if (cmd.produces !== null) {
          try {
            cmd.produces.stateUpdater(getFileInTree(files, cmd.produces.file))
          } catch (e) {
            setCommandOutput(prev => prev + e)
          }
        }
      } catch (e) {
        console.log(e)
        handlers.stdout(`[Playground] ${cmd.name} exited with error ${e}\n`)
        setActiveRightTab("command-output")
        failed = true;
        break;
      }
      handlers.stdout(`[Playground] ${cmd.name} done\n`)
    }

    setRunning(false)
    if (!failed && onDone) {
      onDone()
    }
  }

  const swimCommands = [
    new Command(
      "swim-prepare",
      asyncRunner("swimPrepare"),
      [],
      null
    ),
    new Command(
      "swim",
      asyncRunner("swim"),
      ["build"],
      null
    )
  ]

  const spadeCommands = swimCommands.concat([
    new Command(
      "spade",
      asyncRunner("spade"),
      ["--command-file", "build/commands.json", "-o", "build/spade.sv", "dummy_file", "--no-color"],
      new Product(["build", "spade.sv"], "verilog-product", setVerilogProduct)
    )
  ]);

  const simulationCommands = spadeCommands.concat([
    new Command(
      "verilator",
      async (args, files, options) => {
        const res = await runVerilator(args, files, options);

        if (res.output) {
          if (hdlMod) {
            hdlMod.dispose()
          }
          let mod = new HDLModuleWASM(res.output.modules['TOP'], res.output.modules['@CONST-POOL@'])
          await mod.init()
          mod.powercycle()

          mod.state.a = 5;
          mod.state.b = 6;
          mod.tick2(10)
          console.log(mod.state.out)

          console.log(`Setting hdlMod to ${mod}`)
          setHdlMod(mod)
        } else {
          console.log("No output from verilator")
        }

        return files
      },
      [],
      null
    )
  ])


  const prevSourceCode = useRef(sourceCode);
  useEffect(() => {
    if (sourceCode != prevSourceCode.current)
      setProductsOutOfDate(true);
    prevSourceCode.current = sourceCode;
  }, [sourceCode]);

  useEffect(() => {
    if (hdlMod) {
      hdlMod.state.btn = buttons;
      hdlMod.state.sw = switches;
    }
  }, [hdlMod, buttons, switches])

  // TODO: Re-add this is-rendering logic so we don't unnessecarily render in the background
  const isRendering = useRef<boolean>(false);

  function tabAndPanel({ key, title, titleStyle = {}, content }) {
    return [
      <Tab key={`${key}-tab`} value={key} style={titleStyle}>{title}</Tab>,
      <TabPanel key={`${key}-tabpanel`} value={key} sx={{ padding: 0 }}>{content}</TabPanel>
    ];
  }

  function buttonCount(): number {
    const btn = hdlMod?.globals.vars.btn
    if (btn) {
      return (btn.type.left - btn.type.right) + 1;
    } else {
      return null
    }
  }

  function switchCount(): number {
    const sw = hdlMod?.globals.vars.sw
    if (sw) {
      return (sw.type.left - sw.type.right) + 1;
    } else {
      return null
    }
  }

  function InputHelp() {
    const help = <Box>
      <p>Inputs are automatically added based on the inputs to your top module.</p>
      <ul>
        <li> To add switches, add an input called <code>sw</code></li>
        <li> To add buttons, add an input called <code>btn</code></li>
      </ul>
      <p> Make sure those inputs are marked <code>#[no_mangle]</code></p>
    </Box>
    if (!switchCount() && !buttonCount()) {
      return help
    } else {
      return <Box>
        <p></p>
        <Button
          size='lg'
          variant='outlined'
          startDecorator={<QuestionMarkIcon />}
          loading={running}
          onClick={() => setInputHelpOpen(!inputHelpOpen)}
        >
          Inputs
        </Button>
        {inputHelpOpen ? help : <Box />}
      </Box>

    }
  }

  const rightTabsWithPanels = [
    tabAndPanel({
      key: 'tutorial',
      title: <QuestionMarkIcon />,
      content: helloText()
    }),
    tabAndPanel({
      key: 'command-output',
      title: 'Command output',
      content: terminal(commandOutput)
    }),
    tabAndPanel({
      key: 'canvas',
      title: 'Simulation',
      content:
        <Box display="flex" flexDirection="column">
          {/* spacer */} <Box sx={{ flexGrow: 1 }} />
          <FormLabel>Simulated hardware:</FormLabel>
          <Select value={simKind.kind} onChange={(_e, value) => (setSimKind({ kind: value }))}>
            <Option value={"LED"}>LED</Option>
            <Option value={"VGA"}>VGA</Option>
          </Select>

          <RenderOutput hdlMod={hdlMod} simKind={simKind} />
          <Box>
            <Switches />
            <Buttons count={buttonCount()} setButtons={setButtons} />
            <InputHelp />
          </Box>
        </Box>
    }),
  ];

  const leftTabsWithPanels = [
    tabAndPanel({
      key: 'spade-source',
      title: 'playground.spade',
      content:
        <CodeMirror
          value={sourceCode}
          onChange={setSourceCode}
          theme={mode === 'light' ? eclipse : githubDark}
          extensions={[StreamLanguage.define(spade)]}
        />
    }),
    tabAndPanel({
      key: 'toml-source',
      title: 'swim.toml',
      content: <CodeMirror
        value={tomlCode}
        onChange={setTomlCode}
        theme={mode === 'light' ? eclipse : githubDark}
      />
    }),
  ].concat(isLargeScreen ? [] : rightTabsWithPanels)



  function maybeEditorTab(product: string | null, key: string, title: string, language: string) {
    if (product !== null)
      rightTabsWithPanels.push(tabAndPanel({
        key: key,
        title: title,
        titleStyle: productsOutOfDate ? { textDecoration: 'line-through' } : {},
        content:
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {productsOutOfDate && <Alert variant='soft' color='warning' sx={{ borderRadius: 0 }}>
              The generated Verilog is out of date. Run the program again to refresh it.
            </Alert>}
            <Box sx={{ flexGrow: 1 }}>
              <CodeMirror
                value={product}
                extensions={[
                  verilog({ completion: false })
                ]}
                theme={mode === 'light' ? eclipse : githubDark}
              />
            </Box>
          </Box>
      }));
  }

  maybeEditorTab(verilogProduct, "verilog-product", "Generated Verilog", "verilog")


  return <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
      padding: isLargeScreen ? 2 : 1,
      gap: isLargeScreen ? 2 : 1
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: isLargeScreen ? 2 : 1
      }}>

        <Button
          size='lg'
          sx={{ borderRadius: 10 }}
          variant='outlined'
          startDecorator={<PlayArrowIcon />}
          loading={running}
          onClick={() => runCommands(spadeCommands)}
        >
          Build
        </Button>

        <Button
          size='lg'
          sx={{ borderRadius: 10 }}
          variant='outlined'
          startDecorator={<PlayArrowIcon />}
          loading={running}
          onClick={() => runCommands(simulationCommands, () => setActiveRightTab('canvas'))}
        >
          Simulate
        </Button>

        {/* spacer */} <Box sx={{ flexGrow: 1 }} />

        <Select variant="outlined" color="neutral" placeholder="Preset" value="" onChange={(_e, value) => { onPresetChanged(value) }}>
          {
            Object.keys(presets).map((key) =>
              <Option value={key} key={key}>{presets[key].name}</Option>
            )
          }
        </Select>

        <IconButton
          size='lg'
          sx={{ borderRadius: 10 }}
          color='neutral'
          variant='outlined'
          onClick={() => setSharingOpen(true)}
        ><ShareIcon /></IconButton>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={sharingOpen}
          onClose={(_event, _reason) => setSharingOpen(false)}
        >
          <Link href={
            // base64 overhead is fixed at 33%, urlencode overhead is variable, typ. 133% (!)
            new URL('#' + btoa(JSON.stringify({
              spadeSource: sourceCode, tomlSource: tomlCode, simKind: simKind.kind
            })), window.location.href).toString()
          }>
            Copy this link to share the source code
          </Link>
        </Snackbar>

        <IconButton
          size='lg'
          sx={{ borderRadius: 10 }}
          variant='outlined'
          onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
        >
          {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>

      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: "95%",
        padding: isLargeScreen ? 2 : 1,
        gap: isLargeScreen ? 2 : 1
      }}>

        <Tabs
          sx={{ height: '100%', width: isLargeScreen ? '50%' : "100%" }}
          value={activeLeftTab}
          onChange={(_event, value) => setActiveLeftTab(value as string)}
        >
          <TabList>{leftTabsWithPanels.map(([tab, _panel]) => tab)}</TabList>
          {leftTabsWithPanels.map(([_tab, panel]) => panel)}
        </Tabs>

        {isLargeScreen
          ?
          <Tabs
            sx={{ height: '100%', width: '50%' }}
            value={activeRightTab}
            onChange={(_event, value) => setActiveRightTab(value as string)}
          >
            <TabList>{rightTabsWithPanels.map(([tab, _panel]) => tab)}</TabList>
            {rightTabsWithPanels.map(([_tab, panel]) => panel)}
          </Tabs>
          : <div></div>
        }
      </Box>
    </Box>
  </>;
}

createRoot(document.getElementById('root')!).render(
  <CssVarsProvider>
    <CssBaseline />
    <AppContent />
  </CssVarsProvider>
);

console.log('Build ID:', globalThis.GIT_COMMIT);

// https://esbuild.github.io/api/#live-reload
if (!globalThis.IS_PRODUCTION)
  new EventSource('/esbuild').addEventListener('change', () => location.reload());
