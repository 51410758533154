import { useRef } from "react";
import { HDLModuleWASM } from "./sim/hdlwasm";
import { getVGASignals, waitFor } from "./vga_util";
import { ExpectedSignal, useAnimationFrame } from "./simulation_options";

import { HDLModuleWASM } from "./sim/hdlwasm";
import Box from "@mui/joy/Box";

export function waitFor(mod: HDLModuleWASM, condition: () => boolean, timeout = 10000) {
  let counter = 0;
  while (!condition() && counter < timeout) {
    mod.tick2(1);
    counter++;
  }
}

export class VgaSignals {
  hsync: boolean
  vsync: boolean
  r: number
  g: number
  b: number
}

export function getVGASignals(mod: HDLModuleWASM): VgaSignals {
  return {
    hsync: mod.state.hsync == 1,
    vsync: mod.state.vsync == 1,
    r: mod.state.r,
    g: mod.state.g,
    b: mod.state.b,
  }
}

export function setUserIputs(mod: HDLModuleWASM, inputs: number) {
  mod.state.inputs = inputs
}

export const expectedVgaSignals: ExpectedSignal[] =
  [
    { name: "clk", type: "clock", },
    { name: "hsync", type: "inv &bool", },
    { name: "vsync", type: "inv &bool", },
    { name: "r", type: "inv &uint<8>", },
    { name: "g", type: "inv &uint<8>", },
    { name: "b", type: "inv &uint<8>", },
  ]


export const VgaOutput = ({ hdlMod }: { hdlMod: HDLModuleWASM }) => {
  const canvasRef = useRef<HTMLCanvasElement>()

  useAnimationFrame((deltaTimeMs: number) => {
    const canvas = canvasRef.current;
    if (canvas && hdlMod) {
      const context = canvas.getContext('2d')

      context.fillStyle = "#000000"
      context.fillRect(0, 0, 800, 480)
      const imageData = context.createImageData(640, 480)

      const data = new Uint8Array(imageData.data.buffer);
      frameLoop: for (let y = 0; y < 480; y++) {
        waitFor(hdlMod, () => !getVGASignals(hdlMod).hsync);

        // Wait for back porch before pixels
        for (let x = 0; x < 48; x++) {
          hdlMod.tick2(1)
        }

        for (let x = 0; x < 640; x++) {
          const offset = (y * 640 + x) * 4;
          hdlMod.tick2(1);
          const { hsync, vsync, r, g, b } = getVGASignals(hdlMod);
          if (hsync) {
            break;
          }
          if (vsync) {
            break frameLoop;
          }
          data[offset] = r;
          data[offset + 1] = g;
          data[offset + 2] = b;
          data[offset + 3] = 0xff;
        }
        waitFor(hdlMod, () => getVGASignals(hdlMod).hsync);
      }
      context!.putImageData(imageData, 0, 0);
      waitFor(hdlMod, () => getVGASignals(hdlMod).vsync);
      waitFor(hdlMod, () => !getVGASignals(hdlMod).vsync);

      // Back porch
      for (let y = 0; y < 33; y++) {
        for (let x = 0; x < 800; x++) {
          hdlMod.tick2(1)
        }
      }
    }
  })


  return <Box sx={{ width: 640, height: 480 }}>
    <canvas ref={canvasRef}
      width="640px"
      height="480px" />
  </Box>;
}


