import Box from "@mui/joy/Box";
import { useEffect, useState } from "react";

export function FancyButton(
  {onPointerDown, onPointerUp}: {onPointerDown: () => void, onPointerUp: () => void}
) {
  const [isDown, setIsDown] = useState(false);
  const innerOnPointerDown = () => {
    setIsDown(true)
    console.log("Pointer down")
    if (onPointerDown) {onPointerDown()};
  };
  const innerOnPointerUp = () => {
    setIsDown(false)
    console.log("Pointer up")
    if (onPointerUp) {onPointerUp()};
  };

  const buttonOffset = isDown ? 5 : -5

  return <Box
    sx={{
      width: 40,
      height: 50,
      position: "relative",
      backgrdounColor: "red"
    }}
    onPointerDown={innerOnPointerDown}
    onPointerUp={innerOnPointerUp}
    onMouseLeave={innerOnPointerUp}
  >
    <Box
      sx={{
        top: 11,
        width: 40,
        height: 40,
        backgroundColor: "green",
        borderRadius: 2,
        position: "absolute"
      }}
    ></Box>
    <Box
      sx={{
        zIndex: 0,
        width: 40,
        height: 40,
        backgroundColor: "rgb(180, 180, 180)",
        borderRadius: 2,
        position: "absolute"
      }}
    ></Box>
    <Box
      sx={{
        top: buttonOffset,
        left: 5,
        width: 30,
        height: isDown ? 30 : 40,
        borderRadius: 15,
        backgroundColor: "rgb(50, 50, 50)",
        position: "absolute"
      }}
    ></Box>
    <Box
      sx={{
        top: buttonOffset,
        left: 5,
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: "rgb(100, 100, 100)",
        position: "absolute"
      }}
    ></Box>
  </Box>
}
