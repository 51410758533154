import { SimOutputKind } from "./simulation_options";


type Preset = { name: string, spade: string, toml: string, hardware: SimOutputKind }


import vgaSpade from "../swim_test_project/src/main.spade"
import vgaToml from "../swim_test_project/swim.toml"

import blinkySpade from "../swim_blinky/src/main.spade"
import blinkyToml from "../swim_blinky/swim.toml"

export const presets: Record<string, Preset> = {
  simpleVga: {
    name: "Basic VGA",
    spade: vgaSpade,
    toml: vgaToml,
    hardware: { kind: "VGA" }
  },
  blinky: {
    name: "Blinky",
    spade: blinkySpade,
    toml: blinkyToml,
    hardware: { kind: "LED" }
  }
}
