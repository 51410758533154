// A ~~flappy bird~~ floppy fish game!
// 
// This code uses an experimental fixed point library which is why some
// arithmetic is done with `.add() instead of `+`. Soon spade will support
// operator overloading which will make that code much neater, for now it is a
// bit of a tech demo.
use vga::vga::VgaTiming;
use vga::vga::vga_fsm;
use vga::vga::vga_output;

use fixed::lib::Fp;

use std::conv::bits_to_uint;
use std::conv::int_to_uint;
use std::io::rising_edge;

struct Color {
  r: uint<8>,
  g: uint<8>,
  b: uint<8>
}

enum GameState {
  Start,
  Play {
    player_speed: Fp<16, 4>,
    player_pos: Fp<16, 4>,
    pipes: [uint<4>; 4],
    pipe_offset: uint<8>,
  }
}

pipeline(1) player_sprite(clk: clock, x: uint<3>, y: uint<3>) -> bool {
    let sprite: [uint<8>; 8] = [
      0b01111000,
      0b00111100,
      0b10111110,
      0b11111010,
      0b10111111,
      0b00111110,
      0b01111100,
      0b00001000,
    ];
  reg;
    sprite[y].bits()[trunc(7-x)]
}

entity game(clk: clock, rst: bool, new_frame: bool, btn: bool, game_over: &bool) -> GameState {
  let game_initial = GameState::Play$(
    player_speed: (-15_i12).to_fixed(),
    player_pos: (200_i12).to_fixed(),
    pipes: [4, 8, 6, 7],
    pipe_offset: 4,
  );

  reg(clk) rng reset(rst: [true; 16]) = {
    let bit = rng[0] ^^ rng[2] ^^ rng[3] ^^ rng[5];
    rng[1:16] `concat_arrays` [bit]
  };
  let rng = trunc(bits_to_uint(rng));

  reg(clk) state reset(rst: GameState::Start) = match state {
    GameState::Start => if btn {game_initial} else {state},
    GameState::Play$(player_pos, player_speed, pipe_offset, pipes) => {
      if new_frame {
        let player_speed = if btn {
          (-15_i12).to_fixed()
        } else {
          let acceleration = (1_i16 << 4).to_fixed_raw();
          player_speed.add(acceleration).truncate()
        };

        let player_pos = player_pos.add(player_speed).truncate();

        GameState::Play$(
          player_speed,
          player_pos,
          pipe_offset: trunc(pipe_offset + 4),
          pipes: if pipe_offset == trunc(0u8 - 4) {pipes[1:4] `concat_arrays` [rng]} else {pipes},
        )
      } else {
        if *game_over {GameState::Start} else {state}
      }
    }
  };

  state
}

pipeline(1) draw_game(
  clk: clock,
  x: int<16>,
  y: int<16>,
  player_pos: Fp<16, 4>,
  pipe_offset: uint<8>,
  pipes: [uint<4>; 4],
  game_over: inv &bool,
) -> Color {
    let player_size = 32;
    let pipe_gap = 90;
    let player_in_y = y > player_pos.as_int() && y < trunc(player_pos.as_int() + player_size);
    let player_in_x = x > 20 && x < trunc(20 + player_size);
  
    let pipe_idx = (x + zext(pipe_offset).to_int()) / 256;
    let from_pipe = (x + zext(pipe_offset).to_int()) % 256;
    let pipe = (pipes[trunc(pipe_idx.to_uint())] * 15).to_int();
  
    let is_pipe = (y < pipe || y > trunc(pipe + pipe_gap)) && from_pipe < 20;
    let is_player_bb = player_in_x && player_in_y;
    let player_pixel = inst(1) player_sprite(
      clk,
      trunc((x-20) / 4).to_uint(),
      trunc(((y - player_pos.as_int()) / 4)).to_uint()
    );
  reg;
    let is_player = is_player_bb && player_pixel;
    let color = if is_pipe {
      Color(50, 200, 0)
    } else if is_player {
      Color(0,0,0)
    } else {
      Color(50,50,200)
    };
    set game_over = is_pipe && is_player;
    color
}

// ***********************************************************************
// This is the best place to start playing around with changing the output
// ***********************************************************************
entity pixel_to_color(
  clk: clock,
  rst: bool,
  new_frame: bool,
  pixel: (uint<15>, uint<15>),
  btn: bool
) -> Color {
  let (x, y) = pixel;
  let (x, y): (int<16>, int<16>) = (zext(x).to_int(), zext(y).to_int());

  let (game_over, game_over_inv) = port;
  let state = inst game$(clk, rst, new_frame, btn, game_over);

  let color = match state {
    GameState::Start => Color(0,0,255),
    GameState::Play$(player_pos, player_speed: _, pipe_offset, pipes) => {
      inst(1) draw_game$(
        clk,
        x,
        y,
        player_pos,
        pipe_offset,
        pipes,
        game_over: game_over_inv
      )
    }
  };
  color
}

// ***********************************************************************
// The pixel_to_color entity above is used together with a library for
// driving the VGA monitor. That driving happens here. You probably don't
// need to modify it
// ***********************************************************************

// This is the top module that we will connect to the simulator here,
// or to a real monitor on an FPGA. #[no_mangle] tells the Spade compiler
// to not change any names which makes mapping the signals to simulator
// or physical outputs easier.
#[no_mangle]
entity top(
  // Digital hardware is driven by a clock. Every time it flips from 0 to 1,
  // all the signals in our design will be re-computed
  #[no_mangle] clk: clock,

  #[no_mangle] btn: bool,
  
  // These are the signals we'll send to the display
  #[no_mangle] hsync: inv &bool,
  #[no_mangle] vsync: inv &bool,
  #[no_mangle] r: inv &uint<8>,
  #[no_mangle] g: inv &uint<8>,
  #[no_mangle] b: inv &uint<8>,
) {
  // In order to set our circuit back to its initial state on startup, we'll
  // generate a reset signal to use later
  let rst = inst power_on_reset(clk);

  // We use a VGA library to generate the signals for driving the VGA display.
  // That library is specified in swim.toml
  let vga_state = inst vga_fsm(clk, rst, true, vga_timing());
  let vga_out = vga_output(vga_state);

  // The VGA library gives us an x and y coordinate, and it is our job to fill in
  // the color at that pixel here. Some clock cycles, VGA doesn't output any pixels
  // because it is busy 'syncing', which is why we need to do a match here.
  let color = match vga_out.pixel {
    Some((x, y)) => {
      inst pixel_to_color$(
        clk,
        rst,
        new_frame: inst rising_edge(clk, vga_out.vsync),
        pixel: (int_to_uint(x), int_to_uint(y)),
        btn
      )
    },
    None => Color(0, 0, 0)
  };

  // Finally, we can set the VGA signals
  set r = color.r;
  set g = color.g;
  set b = color.b;
  set hsync = vga_out.hsync;
  set vsync = vga_out.vsync;
}

fn vga_timing() -> VgaTiming {
  VgaTiming$(
    x_pixels: 640,
    x_front_porch: 16,
    x_sync_width: 96,
    x_back_porch: 48,

    y_pixels: 480,
    y_front_porch: 10,
    y_sync_width: 12,
    y_back_porch: 33,
  )
}

entity power_on_reset(clk: clock) -> bool {
  reg(clk) rst_counter: uint<4> initial(5) =
    if rst_counter == 0 {
      0
    } else {
      trunc(rst_counter-1)
    };
  rst_counter != 0
}
