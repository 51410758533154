import Box from "@mui/joy/Box"
import { HDLModuleWASM } from "./sim/hdlwasm";
import { useEffect, useRef, useState } from "react";
import { expectedVgaSignals, VgaOutput } from "./vga_simulation";
import { expectedLedSignals, LedOutput } from "./led_simulation";

// Shamelessly stolen from https://css-tricks.com/using-requestanimationframe-with-react-hooks/
export const useAnimationFrame = (callback: Function) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime)
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once
}

export type ExpectedSignal = { name: string, type: string }

function gatherMissingSignals(mod: HDLModuleWASM, expected: ExpectedSignal[]): ExpectedSignal[] {
  return expected.map((e) => {
    if (mod.state[e.name] === undefined) {
      return e
    } else {
      return null
    }
  })
    .filter((e) => e !== null)
}



export type SimOutputKind =
  | { kind: "VGA" }
  | { kind: "LED" }


function checkMissingSignals(mod: HDLModuleWASM, simKind: SimOutputKind) : ExpectedSignal[] {
  switch (simKind.kind) {
    case "VGA":
      return gatherMissingSignals(mod, expectedVgaSignals)
    case "LED":
      return gatherMissingSignals(mod, expectedLedSignals)
  }
}

export const RenderOutput = ({ simKind, hdlMod }: { simKind: SimOutputKind, hdlMod: HDLModuleWASM }) => {
  if (!hdlMod) {
    return <p>Click Simulate to run the simulation</p>
  }
  const missing = checkMissingSignals(hdlMod, simKind)
  if (missing.length != 0) {
    return <Box>
      <p>The {simKind.kind} simulation requires the following signals in the top module to work</p>
      {
        missing.map((m, i) => <p key={i} style={{fontFamily: "monospace"}}>{m.name}: {m.type}</p>)
      }
      <p><b>Hint: </b> all parameters of the top module must be marked #[no_mangle]</p>
    </Box>
  }
  switch (simKind.kind) {
    case "VGA":
      return <VgaOutput hdlMod={hdlMod} />
    case "LED":
      return <LedOutput hdlMod={hdlMod} />
  }
}
